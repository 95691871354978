<script lang="ts" setup>
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { fetchJson, fetchOptionsJson, Logger } from 'zeed'
import type { Ref } from 'vue'
import type { LoggerInterface } from 'zeed'
import { isServer } from '../lib/client'
import { corsProxy } from '../lib/cors-proxy'
import { checkValidEmail } from '../lib/email-check'
import messages from './app-newsletter.locale.json'

import './app-newsletter.styl'

const props = defineProps<{
  lists?: string[]
  attribs?: Record<string, string>
}>()

// See https://listmonk.app/docs/apis/subscribers/#post-apipublicsubscription
const { t } = useI18n({ messages })

// Get in head of detail view of single entries at https://newsletter.holtwick.de/admin/lists

const {
  newsletterIdList = [
    'd260bd9f-020e-405e-b032-4e7123818180',
    '0a00edc4-1de1-4d40-9eb9-d3c13014bfc8',
    '6e8136a4-014a-4d9d-b14a-d8dbc4de4ada',
    '7e325d95-09ca-4614-a7c8-363893540097',
    'e8b187c8-222d-4dfc-a8f2-8c0d2230728a',
    'bf231d59-f284-4534-8bfa-2ed2066d9443',
  ],
  newsletterHost = 'newsletter.holtwick.de',
} = globalThis.siteConfig

const log: LoggerInterface = Logger('app:newsletter')

let emailFromQuery = ''
try {
  const url = new URL(location.href)
  emailFromQuery = url.searchParams.get('email') ?? ''
}
catch (err) {
}

const email = ref(emailFromQuery)
const emailInput = ref()
const state: Ref<'' | 'send' | 'ok' | 'optin' | 'error'> = ref('')

const isValid = computed(() => checkValidEmail(email.value))

async function doSubscribe(ev: Event) {
  ev.preventDefault()
  if (!isValid.value)
    return
  state.value = 'send'
  const url = `https://${newsletterHost}/api/public/subscription`
  try {
    const result: any = await fetchJson(corsProxy(url), fetchOptionsJson({
      email: email.value,
      list_uuids: props.lists ?? newsletterIdList,
      // attribs: props.attribs ?? {
      //   href: location.href,
      //   lang: navigator.language,
      //   langs: navigator.languages,
      //   ua: navigator.userAgent,
      // },
    }, 'POST'))
    log('result', result)
    if (result?.data) {
      state.value = result?.data?.has_optin === true ? 'optin' : 'ok'
      return
    }
    log.error('result fail', result)
  }
  catch (err) {
    log.error('Registration error', err)
  }
  state.value = 'error'
}

function doFocus(ev: Event) {
  ev.preventDefault()
  emailInput.value.focus()
}
</script>

<template>
  <ClientOnly>
    <div class="app-newsletter">
      <template v-if="!state">
        <div class="app-newsletter-container" @click="doFocus">
          <input id="email" ref="emailInput" v-model="email" type="text" :placeholder="t('newsletter.placeholder')" @keypress.enter="doSubscribe">
          <button class="app-newsletter-button" :class="{ active: isValid }" :disabled="!isValid" @click.stop="doSubscribe">
            {{ t("newsletter.send") }}
          </button>
        </div>
      </template>
      <div v-else-if="state === 'send'">
        {{ t("newsletter.sending") }}
      </div>
      <div v-else-if="state === 'ok'">
        {{ t("newsletter.ok", { email }) }}
      </div>
      <div v-else-if="state === 'optin'">
        {{ t("newsletter.optin", { email }) }}
      </div>
      <div v-else>
        {{ t("newsletter.error") }}
      </div>
    </div>
  </ClientOnly>
  <div v-if="isServer">
    <a :href="`https://${newsletterHost}/subscription/form`" target="_blank">{{ `https://${newsletterHost}/subscription/form` }}</a>
  </div>
</template>
